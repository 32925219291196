/*
    Where we set the typography of the template
 */

// sets default font for the website and links
body {
    @extend .text-color;
    @extend .font-1-normal;
    line-height: 1;
    a {
        @extend .text-color-primary;
        @extend .font-1-normal;
        text-decoration: none !important;
        &:hover, &:focus {
            text-decoration: none !important;
             @extend .text-color-contrast;
        }
    }
    a[href^=tel], a[href^=mailto] {
        &:hover {

        }
    }
}

h1, h2, h3, h4, h5, h6 {
    @extend .font-1-normal;
}

h3 {
    @extend .font-1-bold;
    @extend .text-color-header;
    text-transform: uppercase;
    font-size: $font-size-24;
    transition: .5s;
}
h4 {
  font-size: $font-size-24;
  @extend .font-2-bold;
  padding-bottom: 20px;
  color: $color-black;
  line-height: 1.5;
  transition: .5s;
}

p {
    font-weight: 400;
    line-height: 1.5;
    @extend .font-main;
    color: $color-primary;
    transition: .5s;
    span {
      color: $color-black;
    }
}
ul {padding-left: 20px;}

li {
  @extend .font-2-bold;
  color: $color-black;
  line-height: 1.3;
  transition: .5s;
  padding-bottom: 1rem;
  font-size: $font-size-20;
  &:nth-last-child(){
    padding-bottom:0;
  }
}

.text p, .text div {
    @extend .text-color;
    line-height: 1.4;
}

.text p, .text div {
    @extend .font-1-normal;
}

.text strong {
    color: $color-primary;
    font-weight: 900;
}

.text em {
    color: $color-2;
    font-style: normal;
    font-weight: 600;
}
