// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.nom {
  font-weight: 700!important;
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/

nav.module-menu .menu-desktop {
    background-image: linear-gradient(90deg, rgba(255,255,255,0.9), rgba(255,255,255,0.3), rgba(255,255,255,0.9));
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li > a,
nav.module-menu .menu-desktop .wrapper.right .main-menu-top > ul li.phone > a:hover {
    color: #01632f;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu-top > ul li.phone > a,
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li:hover > a {
    color: #000;
}
nav.module-menu .menu-desktop .wrapper.right .main-menu > ul li:hover {
    border-bottom: 4px solid #000;
}
.ls-numbers .ls-nav-prev, .ls-numbers .ls-nav-next {
    display: none;
}
.slideshow-wrap .title {
    color: #000;
}
#layerslider .ls-inner .ls-wrapper .ls-layer .title h2 {
    font-size: 20px;
    font-weight: 500;
    text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
}
#hero .slideshow-wrap img {
    height: 30vw;
}

/***** bloc intro *****/

#blocIntro {
    background: url("../images/accueil_bg_zone01.jpg");
    display: flex;
    padding: 50px 30px 50px 0px;
    border-top: 30px solid #014e25;
    @media screen and (max-width: $size-xs-max){
      justify-content: center;
      flex-direction: column;
      align-items: center;
      transition: .5s;
      padding: 50px 20px;
    }
}
#blocIntro .blocGauche, #blocIntro .blocDroit {
    width: 50%;
    @media screen and (max-width: $size-xs-max){
      width: 80%;
      transition: .5s;
      text-align: center;
    }
}
#blocIntro .blocGauche .saviezVous {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.6));
    width: 80%;
    padding: 15px 90px;
    position: relative;
    bottom: -70px;
    @media screen and (max-width: $size-xs-max){
      transition: .5s;
      margin: 30px auto;
      bottom: 0;
      @media screen and (max-width: $size-slider-breakpoint){
        transition: .5s;
        width: 90%;
        padding: 15px 10%;
      }
    }
}
#blocIntro .blocDroit p {
    color: #01632f;
    font-weight: 400;
}
#blocIntro .blocDroit span {
    color: #000;
    font-weight: 500;
}
aside .bursts-container {
    border-bottom: 30px solid #014e25;
}

/***** bloc bursts *****/

.bursts .bursts-container .burst .text-container {
    padding: 0.80rem;
}
.bursts-container .burst .text-container .border p {
    color: #015f2d;
    text-transform: uppercase;
    text-align: center;
    width: 68%;
    line-height: 1.6;
    font-size: $font-size-18;
    @extend .font-1-bold;
    text-shadow: 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
    @media screen and (max-width: $size-md-max){
      width: 90%;
      transition: .5s;
      line-height: 1.3;
      @media screen and (max-width: $size-xs-max){
        font-size: 20px;
        transition: .5s;
        @media screen and (max-width: $size-slider-breakpoint){
          font-size: 4vw;
          transition: .5s;
        }
      }
    }
}
.bursts-container .burst .text-container img.img-responsive.imgIcon {
    padding-bottom: 10px;
}
.bursts-container .burst .text-container:hover .border p {
    color: #fff;
    text-shadow: 0 0 10px #fff, 0 0 10px #000, 0 0 10px #fff;
}



div.text-bg {
  background: linear-gradient(to right, $color-grey-darker, $color-grey-light 50%, $color-grey-darker);
  text-align: center;
  max-width: 800px;
  padding: 30px;
  width: 60%;
  margin: 70px 0 0 0;
}

/*******************************************************************************
* X PAGE NETTOYAGE
*/
.nettoyage {
  section.section-01 {

    padding: 90px 0;
    border-top: 30px solid $color-primary;

    div.container-fluid.row {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    div.text-box {
      width: 80%;
      max-width: 1200px;
      text-align: center;
    }



  }

  section.section-02 {
    background-image: url('../images/nettoyage_section02_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 90px 10%;
  }


  section.section-03 {
    background-image: url('../images/nettoyage_section03_bg.jpg');
    background-position: center;
    background-size: cover;
    padding: 120px 10%;
    text-align: right;
    h4 {
      padding-bottom: 30px;
    }
  }

  section.section-04 {
    padding: 90px 10%;
    background-color: $color-primary;

    li, h4 {color: $color-white;}

  }


  section.section-05 {
    background-image: url('../images/nettoyage_section05_bg.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 0 90px;


    div.container-fluid.row {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    div.text-box {
      width: 80%;
      max-width: 1200px;
      margin-top: 50px;
    }

  }

}


/*******************************************************************************
* X PAGE INSPECTION
*/
.inspection {

  section.section-01 {
    border-top: 30px solid $color-primary;
    text-align: center;
    padding: 90px 10%;
  }


  section.section-02 {
    background-image: url('../images/inspection_section02_robot.png'),
                      url('../images/inspection_section02_bg.jpg');

    background-position: right bottom, center ;
    background-repeat: no-repeat;
    background-size: 40% auto,cover;
    padding: 100px 10%;

    p {
      color: $color-black;
      line-height: 2;
      @extend .font-2-bold;
    }

  }


  section.section-03 {
    background: $color-primary;
    padding: 90px 10%;

    li {
      color: $color-white;
      padding-bottom: 0;
    }
    p {
      color: $color-white;
      padding-bottom: 25px;
    }
  }


  section.section-04 {
    background-image: url('../images/inspection_section04_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;

    div.text-bg {
      width: 50%;
      transition: .5s;
      margin-top: 20px;
      margin-right: 0;
      margin-left: auto;
      @media screen and (max-width: $size-sm-max){
        margin: auto;
        width: 80%;
        transition: .5s;
      }
    }
  }


  section.section-05 {
    background-image: url('../images/inspection_section05_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
  }

  section.section-06 {
    background-image: url('../images/inspection_section02_bg.jpg');

    background-position: center ;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 10%;

    p {
      color: $color-black;
      line-height: 2;
      text-align: center;
      @extend .font-2-bold;
    }
  }

}

/*******************************************************************************
* X PAGE Equipements
*/
.equipements {
  section.section-01 {
    background-image: url('../images/equipements_section01_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 0;
    border-top: 30px solid $color-primary;

    div.container-fluid.row {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    div.text-box {
      width: 80%;
      max-width: 1200px;
      text-align: center;
    }

  }

  section.section-02 {
    background: $color-white;
    padding: 90px 10%;

    div.container-fluid.row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      transition: .5s;
      flex-wrap: wrap;
    }

    div.text-box {
      flex: 0 0 auto;
    }

    div.image-box {
      flex: 0 0 30%;
    }

    li {
      font-weight: 700;
      padding-bottom: 1rem;
    }

    @media screen and (max-width: $size-sm-max){
      transition: .5s;
      justify-content: center;
      div.text-box {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
      }

      div.image-box {
        flex: 0 0 50%;
      }

    }

  }


  section.section-03 {
    background-image: url('../images/equipements_section03_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 90px 5%;

    h4 {
      text-transform: uppercase;
      font-size: $font-size-30;
      font-weight: bold;
    }
    h5 {
      font-size: $font-size-28;
      font-weight: bold;
      padding-bottom: 20px;
      color: #000;
      font-family: "Libre Baskerville", Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
    }
    p {
      color: #01632f;
      padding-bottom: 1rem;
      font-size: $font-size-28;
      font-weight: normal;
      line-height: 1.5;
      transition: .5s;
    }
  }

  section.blocLogo {
    padding: 75px 2%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

/*******************************************************************************
* X PAGE Coordonnées
*/

.form {
    section.section-01 {
      background-image: url('../images/coordonnees_section01_bg.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;

      padding: 90px 10%;
      border-top: 30px solid $color-primary;

      div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      p {font-weight: 700;}

      div.text-box {
        width: 80%;
        max-width: 1200px;
        text-align: center;
        margin-bottom: 50px;
      }

      form {
        width: 100%;

        label {
          text-transform: uppercase;
          color: $color-primary;
          font-size: $font-size-22;
          @extend .font-2-bold;
          padding-left: 0;
        }

        .g-recaptcha > div {
          margin: auto;
        }

        .form-control {
          box-shadow: 0 2px 5px $color-grey;
        }
      }

    }

    section.phone-section {
      background: $color-primary;
      padding: 20px;

      div.container-fluid.row {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }

      h4 {
        color: $color-white;
        font-size: $font-size-24;
        padding: 20px;
      }
      a {
        color: $color-white;
        &:hover, &:focus {
          color: $color-black;
        }
      }

    }
}


/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: 1550px) {

#hero .slideshow-wrap img {
    height: 28vw;
}
}

@media screen and (max-width: 1305px) {

#hero .slideshow-wrap img {
    height: 26vw;
}
}

@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
   
.equipements section.blocLogo {
  flex-direction: column;
}
.equipements section.blocLogo .logo.res{
  padding-bottom: 30px;
}
}
